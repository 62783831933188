<template>
    <div class="card card-primary text-xs"  v-if="timeTracking.employees">
        <div class="card-header">
            <div class="card-title">
                Urlaub
            </div>
            <div class="card-tools">

            </div>
        </div>
        <div class="card-body">
            <div class="card card-outline card-primary">
                <div class="card-header">
                    <div class="card-title">
                        <ul class="pagination pagination-month justify-content-center" v-if="months.length > 0">
                            <li class="page-item" v-for="year in years" :key="year.year" :class="year.klasse"  @click="selectYear(year.year)">
                                <a class="page-link" href="#">
                                    <h3 class="page-year">{{ year.year }}</h3>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-tools">
                        <button class="btn btn-primary btn-sm mr-1" @click="openFormModal()" v-if="$auth.user().employee != null">
                            <i class="fas fa-plus"></i> Anfrage
                        </button>
                        <button class="btn btn-secondary btn-sm" @click="getDays()">
                            <i class="fas fa-sync"></i>
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row" v-if="$auth.user().employee != null">
                        <div class="col-md-3">

                        </div>
                        <div class="col-md-6">
                            <div class="card card-secondary card-outline text-sm">
                                <div class="card-body">
                                    <table class="table table-hover text-center">
                                        <thead>
                                            <tr>
                                                <th>Urlaubsanspruch <br>(+ Resturlaub vom Vorjahr)</th>
                                                <!-- <th>Urlaub aus dem Vorjahr</th> -->
                                                <th>Genommener Urlaub</th>
                                                <th>Resturlaub</th>
                                                <th>Geplanter Urlaub</th>
                                                <th>Krankheitstage</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{calculateUrlaubabspruch(findEmployee())}}</td>
                                                <!-- <td>{{findEmployee().rest_holidays}}</td> -->
                                                <td>{{calculateUG(findEmployee())}}</td>
                                                <td>{{calculateRestHoliday(findEmployee())}}</td>
                                                <td>{{calculateGU(findEmployee())}}</td>
                                                <td>{{calculateIllDays(findEmployee())}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">

                        </div>
                    </div>
                    <div class="table-responsive" style="max-height: 60vh;min-height: 60vh">
                        <table class="table table-borderless" style="overflow: auto">
                            <thead class="first">
                                <!-- <tr>
                                    <th style="min-width: 150px;" class="bg-white" colspan="1"></th>
                                    <th v-for="date in dates" :key="date.day" class="text-xs text-left" style="writing-mode: tb-rl; transform: rotate(-180deg); height: 130px; word-break: break-word; background-color: rgba(0,0,0,0)">
                                        {{date.official_holiday_name}}
                                    </th>
                                </tr> -->
                                <tr>
                                    <th style="min-width: 150px; border: none;" class="bg-white" colspan="1"></th>
                                    <th v-for="month in months" :key="month.month" :colspan="month.days" class="text-center bg-danger month" style="border-right: solid white 3px;">
                                        <div class="row">
                                            <div class="col" v-for="index in 10" :key="index * 1000">
                                                {{month.name}}
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="table-primary second" style="border: none;">Mitarbeiter</th>
                                    <th v-for="(date, index) in dates" :key="date.day" class="overflow text-center p-0 pb-2 pt-2" :style="generateColorTitle(date, index)" :ref="date.date == currentDate.format('YYYY-MM-DD') ? 'yes':'no'" v-b-tooltip.hover.top="generateHolidayTooltip(index) != '' ? generateHolidayTooltip(index) : null" style="width: 3vh; max-width: 3vw;"> 
                                        <span style="color: #b57900;" class="p-0" v-b-popover.hover.bottom="createOpenSundayTag(date.date)">{{createOpenSundayTag(date.date)}} <br></span>
                                        {{generateDay(formatDate(date.date)).weekDay}} <br>
                                        {{generateDay(formatDate(date.date)).day}} <br>
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-for="group in groups" :key="group.id">
                                <tr>
                                    <th style="min-width: 200px;border: none;" class="table-light text-center">
                                        <u>
                                            {{group.name}}
                                        </u>
                                    </th>
                                </tr>
                                <tr v-for="(employee, eIndex) in timeTracking.employees" :key="employee.id">
                                    <template v-if="group.id == employee.employee_groups_id">
                                        <th style="min-width: 150px;border: none;" :class="isEmployee(employee) ? 'table-dark': 'table-light'">
                                            {{employee.first_name}} {{employee.last_name}}
                                        </th>
                                        <template v-for="(date, index) in dates">
                                            <template v-if="blockedDays[index] == false">
                                                <template v-if="isAbwesend(date.date, employee) == false">
                                                    <template v-if="date.holidays[eIndex] != null">

                                                        <!-- Ist Bestätiger Urlaub -->
                                                        <template v-if="date.holidays[eIndex].state.name === 'confirmed' || date.holidays[eIndex].state.name === 'confirmed_unpaid'">
                                                            <template v-if="isSunday(date.date) == true">
                                                                <td class="hover text-center" style="background-color: #fffbc7" :key="date.date"></td>
                                                            </template>
                                                            <template v-else>
                                                                <td class="hover text-center" style="background-color: #fffbc7" :key="date.date">U</td>
                                                            </template>
                                                        </template>

                                                        <!-- Ist Anfrage -->
                                                        <template v-else-if="date.holidays[eIndex].state.name === 'requested'">
                                                            <template v-if="isSunday(date.date) == true">
                                                                <td class="hover text-center table-info" :key="date.date" @contextmenu.prevent="openWishModal(date.holidays[eIndex])" @dblclick="openWishModal(date.holidays[eIndex])"></td>
                                                            </template>
                                                            <template v-else>
                                                                <td class="hover text-center table-info" :key="date.date" @contextmenu.prevent="openWishModal(date.holidays[eIndex])" @dblclick="openWishModal(date.holidays[eIndex])">W</td>
                                                            </template>
                                                        </template>



                                                        <!-- Ist Krank -->
                                                        <template v-else-if="date.holidays[eIndex].state.name === 'ill' || date.holidays[eIndex].state.name === 'ill_unpaid'">
                                                            <template v-if="isEmployee(employee)">
                                                                <td class="hover text-center" style="background-color: #c78fff" :key="date.date">K</td>
                                                            </template>
                                                            <template v-else>
                                                                <td class="hover text-center table-secondary" :key="date.date">A</td>
                                                            </template>
                                                        </template>

                                                        <!-- Ist abwesend -->
                                                        <template v-else-if="date.holidays[eIndex].state.name === 'absend'">
                                                            <template v-if="isEmployee(employee)">
                                                                <td class="hover text-center table-secondary" :key="date.date" @contextmenu.prevent="openAbsend(date.holidays[eIndex])" v-b-popover.hover.top="'Rechtsklick zum bearbeiten'">A</td>
                                                            </template>
                                                            <template v-else>
                                                                <td class="hover text-center table-secondary" :key="date.date">A</td>
                                                            </template>
                                                        </template>


                                                        <!-- Ist gegenvorschlag -->
                                                        <template v-else-if="date.holidays[eIndex].state.name === 'returned_request'">
                                                            <template v-if="isSunday(date.date) == true">
                                                                <td class="hover text-center" style="background-color: #e8a797;" :key="date.date" @contextmenu.prevent="openReturnedModal(date.holidays[eIndex])" @dblclick="openReturnedModal(date.holidays[eIndex])"></td>
                                                            </template>
                                                            <template v-else>
                                                                <td class="hover text-center" style="background-color: #e8a797;" :key="date.date" @contextmenu.prevent="openReturnedModal(date.holidays[eIndex])" @dblclick="openReturnedModal(date.holidays[eIndex])">G</td>
                                                            </template>
                                                        </template>


                                                        <template v-else-if="date.holidays[eIndex].state.name === 'absend_morning'">
                                                            <template v-if="isEmployee(employee)">
                                                                <td 
                                                                    class="hover text-center table-secondary" 
                                                                    :key="date.date" 
                                                                    @contextmenu.prevent="openAbsend(date.holidays[eIndex])"
                                                                    v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                                >AV</td>
                                                            </template>
                                                            <template v-else>
                                                                <td 
                                                                    class="hover text-center table-secondary" 
                                                                    :key="date.date" 
                                                                >AV</td>
                                                            </template>
                                                        </template>
                                                        <template v-else-if="date.holidays[eIndex].state.name === 'absend_afternoon'">
                                                            <template v-if="isEmployee(employee)">
                                                                <td 
                                                                    class="hover text-center table-secondary" 
                                                                    :key="date.date" 
                                                                    @contextmenu.prevent="openAbsend(date.holidays[eIndex])"
                                                                    v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                                >AN</td>
                                                            </template>
                                                            <template v-else>
                                                                <td 
                                                                    class="hover text-center table-secondary" 
                                                                    :key="date.date" 
                                                                >AN</td>
                                                            </template>
                                                        </template>
                                                    </template>
                                                    <!-- Leere Felder -->
                                                    <template v-else>
                                                        <td class="hover text-center" :key="date.date"
                                                            v-bind:attr="date.date"
                                                            v-on:click.left="isEmployee(employee) ? activateRightclick($event, employee.id) : ''"
                                                            @contextmenu.prevent="rightClickActive = false; uncolorIt();selectedDates = []"
                                                            @mouseover="isEmployee(employee) ? colorIt($event, employee.id, date.date) : ''"
                                                        ></td>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <td class="hover text-center table-secondary" :key="date.date">
                                                    </td>
                                                </template>
                                            </template>

                                            <!-- SPERRTAGE -->
                                            <template v-else>
                                                <template v-if="isAbwesend(date.date, employee) == false">
                                                    <template v-if="date.holidays[eIndex] != null">
                                                        <template v-if="date.holidays[eIndex].state.name === 'confirmed' || date.holidays[eIndex].state.name === 'confirmed_unpaid'">
                                                            <td class="text-center" style="background-color: #fffbc7" :key="date.date">X</td>
                                                        </template>
                                                        <template v-else-if="date.holidays[eIndex].state.name === 'requested'">
                                                            <td class="text-center table-info" :key="date.date" @contextmenu.prevent="openWishModal(date.holidays[eIndex])" @dblclick="openWishModal(date.holidays[eIndex])">X</td>
                                                        </template>
                                                        <template v-else-if="date.holidays[eIndex].state.name === 'ill' || date.holidays[eIndex].state.name === 'ill_unpaid'">
                                                            <td class="text-center" style="background-color: #c78fff" :key="date.date">X</td>
                                                        </template>
                                                        <template v-else-if="date.holidays[eIndex].state.name === 'absend'">
                                                            <template v-if="isEmployee(employee)">
                                                                <td 
                                                                    class="hover text-center table-secondary" 
                                                                    :key="date.date" 
                                                                    @contextmenu.prevent="openAbsend(date.holidays[eIndex])"
                                                                    v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                                >X</td>
                                                            </template>
                                                            <template v-else>
                                                                <td 
                                                                    class="hover text-center table-secondary" 
                                                                    :key="date.date" 
                                                                >X</td>
                                                            </template>
                                                        </template>
                                                        <template v-else-if="date.holidays[eIndex].state.name === 'absend_morning'">
                                                            <template v-if="isEmployee(employee)">
                                                                <td 
                                                                    class="hover text-center table-secondary" 
                                                                    :key="date.date" 
                                                                    @contextmenu.prevent="openAbsend(date.holidays[eIndex])"
                                                                    v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                                >AV</td>
                                                            </template>
                                                            <template v-else>
                                                                <td 
                                                                    class="hover text-center table-secondary" 
                                                                    :key="date.date" 
                                                                >AV</td>
                                                            </template>
                                                        </template>
                                                        <template v-else-if="date.holidays[eIndex].state.name === 'absend_afternoon'">
                                                            <template v-if="isEmployee(employee)">
                                                                <td 
                                                                    class="hover text-center table-secondary" 
                                                                    :key="date.date" 
                                                                    @contextmenu.prevent="openAbsend(date.holidays[eIndex])"
                                                                    v-b-popover.hover.top="'Rechtsklick zum bearbeiten'"
                                                                >AN</td>
                                                            </template>
                                                            <template v-else>
                                                                <td 
                                                                    class="hover text-center table-secondary" 
                                                                    :key="date.date" 
                                                                >AN</td>
                                                            </template>
                                                        </template>
                                                        <template v-else-if="date.holidays[eIndex].state.name === 'returned_request'">
                                                            <td class="hover text-center" style="background-color: #e8a797;" :key="date.date" @contextmenu.prevent="openReturnedModal(date.holidays[eIndex])" @dblclick="openReturnedModal(date.holidays[eIndex])"></td>
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        <td class="hover text-center" :key="date.date"
                                                            v-bind:attr="date.date"
                                                            v-on:click.left="$auth.user().employee.id == employee.id ? activateRightclick($event, employee.id) : ''"
                                                            @contextmenu.prevent="rightClickActive = false; uncolorIt();selectedDates = []"
                                                            @mouseover="isEmployee(employee) ? colorIt($event, employee.id, date.date) : ''"
                                                        >X</td>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <td class="hover text-center table-secondary" :key="date.date">
                                                        X
                                                    </td>
                                                </template>
                                            </template>
                                        </template>
                                    </template>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row">
                        <div class="col-md-1 text-center">
                            <div style="background-color: #c78fff;padding: 2%">
                                K
                            </div>
                            Krank
                        </div>
                        <div class="col-md-1 text-center">
                            <div style="background-color: #fffbc7;padding: 2%">
                                U
                            </div>
                            Urlaub
                        </div>
                        <div class="col-md-1 text-center">
                            <div style="padding: 2%" class="table-info">
                                W
                            </div>
                            Anfrage
                        </div>
                        <div class="col-md-1 text-center">
                            <div style="background-color: #e8a797;padding: 2%">
                                G
                            </div>
                            Gegenvorschlag
                        </div>
                        <div class="col-md-1 text-center">
                            <div style="padding: 2%" class="table-secondary">
                                A
                            </div>
                            Abwesend
                        </div>
                        <div class="col-md-6 text-right text-lg">
                            <div class="row">
                                <div class="col">

                                </div>
                                <div class="col">
                                    V = Vormittags
                                </div>
                                <div class="col">
                                    N = Nachmittags
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal
            id="createWish"
            :title="'Urlaubswunsch'"
            @hide="''"
            @ok.prevent="createFormSubmit"
            size="xl"
            :ok-title = "'Bestätigen'"
            :cancel-title="'Abbrechen'"
            v-b-modal.modal-lg
            class="text-sm"
            @shown="form.errors.clear()"
        >
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="state">Typ*</label>
                        <select class="form-control" v-model="form.status_id" :class="{'is-invalid': form.errors.has('status_id')}">
                            <option :value="null">-- Bitte wählen --</option>
                            <template :value="state.id" v-for="state in states">
                                <option :key="state.id" v-if="state.name != 'ill' && state.name != 'ill_unpaid' && state.name != 'confirmed_unpaid'" :value="state.id">{{state.description}}</option>
                            </template>
                        </select>
                        <has-error :form="form" field="status_id"></has-error>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Von: </label>
                        <input type="date" class="form-control" v-model="form.start_date" :class="{'is-invalid': form.errors.has('start_date')}">
                        <has-error :form="form" field="start_date"></has-error>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Bis: </label>
                        <input type="date" class="form-control" v-model="form.end_date" :class="{'is-invalid': form.errors.has('end_date')}">
                        <has-error :form="form" field="end_date"></has-error>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ hide, ok}">
                <b-button size="sm" variant="secondary" @click="hide()">
                    Schließen
                </b-button>
                <b-button size="sm" variant="primary" @click="ok()">
                    Anfragen
                </b-button>
            </template>
        </b-modal>
        <b-modal
            id="wishModal"
            :title="'Mitarbeiterwunsch'"
            @hide="''"
            @ok.prevent="editHolidayWishSubmit"
            size="xl"
            :ok-title = "'Bestätigen'"
            :cancel-title="'Abbrechen'"
            v-b-modal.modal-lg
            class="text-sm"
            @cancel.prevent="deleteWish()"
            @shown="wishModal.errors.clear()"
        >
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Von*:</label>
                        <input type="date" v-model="wishModal.start_date" class="form-control">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Bis*:</label>
                        <input type="date" v-model="wishModal.end_date" class="form-control">
                    </div>
                </div>
            </div>
            <template #modal-footer="{ cancel, hide, ok}">
                <b-button size="sm" variant="secondary" @click="hide()">
                    Schließen
                </b-button>
                <b-button size="sm" variant="danger" @click="cancel()">
                    Löschen
                </b-button>
                <b-button size="sm" variant="primary" @click="ok()">
                    Speichern
                </b-button>
            </template>
        </b-modal>
        <b-modal
            id="returnedModal"
            :title="'Gegenvorschlag'"
            @hide="''"
            @ok.prevent="ReturnedSubmit"
            size="xl"
            :ok-title = "'Bestätigen'"
            :cancel-title="'Abbrechen'"
            v-b-modal.modal-lg
            class="text-sm"
            @cancel.prevent="deleteReturnedRequest()"
            @shown="returnedModal.errors.clear()"
        >
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Von: </label>
                        <input type="date" class="form-control" v-model="returnedModal.start_date" disabled>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Bis: </label>
                        <input type="date" class="form-control" v-model="returnedModal.end_date" disabled>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" role="switch" v-model="newWish">
                        <label>Anderen Wunsch äußern?</label>
                    </div>
                </div>
            </div>
            <div class="row" v-if="newWish">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Von: </label>
                        <input type="date" class="form-control" v-model="returnedModal.compare_start_date">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Bis: </label>
                        <input type="date" class="form-control" v-model="returnedModal.compare_end_date">
                    </div>
                </div>
            </div>
            <template #modal-footer="{ cancel, hide, ok}">
                <b-button size="sm" variant="secondary" @click="hide()">
                    Schließen
                </b-button>
                <b-button size="sm" variant="danger" @click="cancel()">
                    Vorschlag ablehnen
                </b-button>
                <b-button size="sm" variant="primary" @click="ok()" v-if="!newWish">
                    Vorschlag annehmen
                </b-button>
                <b-button size="sm" variant="primary" @click="ok()" v-if="newWish">
                    Neuen Wunsch äußern
                </b-button>
            </template>
        </b-modal>
        <b-modal
            id="absendModal"
            :title="'Bearbeiten'"
            @hide="''"
            @ok.prevent="saveAbsend"
            size="xl"
            :ok-title = "'Bestätigen'"
            :cancel-title="'Abbrechen'"
            v-b-modal.modal-lg
            class="text-sm"
            @cancel.prevent="destroyAbsend"
            @shown="returnedModal.errors.clear()"
        >
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Typ*</label>
                        <select class="form-control" v-model="absendModal.status_id" :class="{'is-invalid': absendModal.errors.has('status_id')}">
                            <option :value="null">-- Bitte wählen --</option>
                            <template v-for="state in states">
                                <option :value="state.id" :key="state.id" v-if="state.name != 'confirmed' && state.name != 'ill'">{{state.description}}</option>
                            </template>
                        </select>
                        <has-error :form="absendModal" field="status_id"></has-error>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Von: </label>
                        <input type="date" class="form-control" v-model="absendModal.start_date" :class="{'is-invalid': absendModal.errors.has('start_date')}">
                        <has-error :form="absendModal" field="start_date"></has-error>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Bis: </label>
                        <input type="date" class="form-control" v-model="absendModal.end_date" :class="{'is-invalid': absendModal.errors.has('end_date')}">
                        <has-error :form="absendModal" field="end_date"></has-error>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ cancel, hide, ok}">
                <b-button size="sm" variant="secondary" @click="hide()">
                    Schließen
                </b-button>
                <b-button size="sm" variant="danger" @click="cancel()">
                    Löschen
                </b-button>
                <b-button size="sm" variant="primary" @click="ok()">
                    Speichern
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import holidayMoment from 'holiday-de'
import moment from 'moment'
export default {
    name: "HolidayPlanningIndex",
    title: "Abwesenheitsplaner",
    data() {
        return {
            selectedEmployee: null,
            currentDate: moment(),
            showDate: moment(),
            months: [],
            years: [],

            states: [],
            blockedDays: [],
            holidays: [],
            dates: [],
            groups: [],

            selectedYear: 0,
            newWish: false,
            workplan: [],

            form: new window.Form({
                employee_id: null,
                start_date: null,
                end_date: null,
                status_id: null,
            }),
            wishModal: new window.Form({
                id: null,
                start_date: null,
                end_date: null,
                status_id: null,
                employee_id: null,
            }),
            returnedModal: new window.Form({
                id: null,
                start_date: null,
                end_date: null,
                compare_start_date: null,
                compare_end_date: null,
                status_id: null,
                employee_id: null,
            }),
            absendModal: new window.Form({
                id: null,
                start_date: null,
                end_date: null,
                status_id: null,
                employee_id: null,
            }),

            timeTracking: {},
            currentEmployee: null,
            rightClickActive: false,
            selectedDates: [],

            //EINSTELLUNGEN
            settings: {
                accessNextYear: 11, //Wann der Urlaubsplaner für nächstes Jahr freigeschaltet wird.
                firstShowedYear: 1, //Anzahl vergangender Jahre die angezeigt werden sollen
                lastShowedYear: 1, //Anzahl zukünftiger Jahrte die angezeigt werden sollen
            }
        }
    },
    watch: {
        newWish: {
            handler() {
                if(this.newWish == false)
                {
                    this.returnedModal.compare_start_date = null;
                    this.returnedModal.compare_end_date = null;
                } else
                {
                    this.returnedModal.compare_start_date = this.returnedModal.start_date;
                    this.returnedModal.compare_end_date = this.returnedModal.end_date;
                }
            }
        },
        'timeTracking.employees': {
            handler() {
                this.calculateAllEmployeeHolidays();
            },
            deep: true
        }
    },
    methods: {
        employeeIsWorking(date, employee_id) {
            let isWorking = false;
            this.workplan.forEach((week) => {
                let worker = week.workers.find(element => element.employee_id == employee_id && element.date == date);
                if(worker != null && worker != undefined)
                {
                    isWorking = true;
                }
            })

            return isWorking;
        },
        isEmployee(employee)
        {
            if(this.$auth.user().employee == null)
            {
                return false;
            }

            if(employee.id == this.$auth.user().employee.id)
            {
                return true;
            }
            return false;
        },
        getGroups()
        {
            this.axios
                .get("/employee/groups")
                .then((response) => {
                    this.groups = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden der Mitarbeitergruppen ist etwas schief gelaufen'
                    })
                })
        },
        openAbsend(timeRange)
        {
            this.absendModal.fill(timeRange);
            this.openModal('absendModal');
        },
        saveAbsend()
        {
            this.absendModal.post("/private-holidays/update")
                .then((response) => {
                    this.closeModal('absendModal')
                    let index = this.timeTracking.employees.findIndex(element => element.id == response.data.data.id);
                    this.timeTracking.employees.splice(index, 1, response.data.data);
                    this.form.reset();
                })
                .catch(() => {

                });
        },
        destroyAbsend()
        {
            this.axios
                .post("/private-holidays/destroy", {
                    id: this.absendModal.id,
                    employee_id: this.absendModal.employee_id
                })
                .then(() => {
                    let index = this.timeTracking.employees.findIndex(element => element.id == this.absendModal.employee_id);
                    let holiday = this.timeTracking.employees[index].private_holidays.findIndex(element => element.id == this.absendModal.id);
                    this.timeTracking.employees[index].private_holidays.splice(holiday, 1);
                    this.absendModal.reset();
                    this.closeModal('absendModal')
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                })
        },
        createOpenSundayTag(date)
        {
            let tag = "VO";
            this.timeTracking.open_sundays.forEach(store => {
                store.open_sundays.forEach(sunday => {
                    if(sunday.date == date)
                    {
                        tag += store.name.charAt(0) + "/";
                    }
                })
            });
            if(tag != "VO")
            {
                tag = tag.slice(0, -1,);
            } else
            {
                tag = null;
            }
            return tag;
        },
        dateSelected(target)
        {
            return this.selectedDates.includes(target);
        },
        activateRightclick(evt, employee, date) {
            if(this.rightClickActive)
            {
                this.rightClickActive = false;
                this.removeIt();
            } else
            {
                this.selectedEmployee = employee;
                this.selectedDates = [];
                this.rightClickActive = true;
                this.colorIt(evt, employee, date);
            }
        },
        colorIt(evt, employee)
        {
            let klass = 'table-active';
            if(this.rightClickActive)
            {
                if(this.selectedEmployee == employee)
                {
                    if(!this.dateSelected(evt.target))
                    {
                        if(this.selectedDates.length == 0)
                        {
                            this.selectedDates.push(evt.target);
                            evt.target.classList.add(klass);
                        } else
                        {
                            let isNextTo = false;
                            this.selectedDates.forEach(element => {
                                let compare = moment(this.formatDate(element.getAttribute('attr')));
                                if(moment(this.formatDate(evt.target.getAttribute('attr'))).subtract(1, 'days').format('YYYY-MM-DD') == compare.format('YYYY-MM-DD') 
                                    || moment(this.formatDate(evt.target.getAttribute('attr'))).add(1, 'days').format('YYYY-MM-DD') == compare.format('YYYY-MM-DD'))
                                {
                                    isNextTo = true;
                                }
                            });
                            if(isNextTo)
                            {
                                this.selectedDates.push(evt.target);
                                evt.target.classList.add(klass);
                            }

                        }
                    }
                }
            }
        },
        uncolorIt()
        {
            this.selectedDates.forEach(element => {
                element.classList.remove('table-active');
            })
        },
        removeIt()
        {
            this.uncolorIt();

            let max = Math.max(
                ...this.selectedDates.map(element => {
                    return moment(this.formatDate(element.getAttribute('attr')));
                })
            );            
            let min = Math.min(
                ...this.selectedDates.map(element => {
                    return moment(this.formatDate(element.getAttribute('attr')));
                })
            );

            this.openFormModalStartEndDate(moment(min).format('YYYY-MM-DD'), moment(max).format('YYYY-MM-DD'), this.selectedEmployee);

            this.selectedDates = [];
        },




        findEmployee()
        {
            return this.timeTracking.employees.find(element => element.id == this.$auth.user().employee.id);
        },

        calculateDays()
        {
            let days = [];
            var a = moment({
                years: this.selectedYear,
                months: 0,
                date: 1,
            });
            var b = moment({
                years: this.selectedYear,
                months: 11,
                date: 31,
            });

            holidayMoment.setState('nw');
            // If you want an inclusive end date (fully-closed interval)
            for (var m = moment(a); m.diff(b, 'days') <= 0; m.add(1, 'days')) {
                days.push({
                    date: m.format('YYYY-MM-DD'),
                    official_Holiday: holidayMoment.isHoliday(m) == false ? false : true,
                    official_holiday_name: holidayMoment.isHoliday(m) == false ? "" : holidayMoment.isHoliday(m),
                })
            }
            this.dates = days;
        },

        //Showed Months
        generateMonths(){
            var year = this.selectedYear;
            this.months = [];
            for(var i=1; i<=12; i++){
            var date = moment({
                year: year,
                month: i-1,
                day: 1
            });
            this.months.push({
                year: year,
                week: date.week(),
                name: date.locale('de').format('MMMM'),
                days: date.daysInMonth(),
                month: i,
            });
            }
        },

        isSunday(date)
        {
            let day = moment(this.formatDate(date)).locale("de").format("dddd");
            let official_Holiday = this.dates.find(item => item.date == date).official_Holiday;
            if(official_Holiday == true)
            {
                return true;
            } else if(day == "Sonntag")
            {
                return true;
            }
            return false;
            // return day == "Sonntag" ? true : false;
        },
        //Year Navigation
        generateYears()
        {
            this.years = [];
            var currYear = parseInt(this.currentDate.format('YYYY'));
            var currMonth = parseInt(this.currentDate.format('M'));

            for(var i = -1 * this.settings.firstShowedYear; i <= this.settings.lastShowedYear; i += 1)
            {
                var klasse = "";


                if(currYear+i > currYear)
                {
                    if(currMonth >= this.settings.accessNextYear && currYear+1 == currYear+i)
                    {
                        //NICHTS
                    } else
                    {
                        klasse += ' disabled';
                    }
                }


                if(currYear+i == this.selectedYear)
                {
                    klasse += ' active';
                }
                this.years.push({
                    year: currYear+i,
                    klasse,
                });
            }
        },

        //Chose a Year
        selectYear(year)
        {
            var currYear = parseInt(this.currentDate.format('YYYY'));
            var currMonth = parseInt(this.currentDate.format('M'));
            if(currYear < year)
            {
                if(currMonth >= this.settings.accessNextYear && currYear+1 == year)
                {
                    //NICHTS
                } else
                {
                    return;
                }
            }
            this.selectedYear = year;
            this.getDays();
            this.calculateDays();
            this.generateYears();
            this.uncolorIt();
            this.selectedDates = [];
            this.rightClickActive = false;
        },


        //Colorize table header
        generateColorTitle(date, index)
        {
            // let klasse = "";
            // let day = moment(this.formatDate(date.date)).locale("de").format("dddd");

            // if(this.holidays[index] != null)
            // {
            //     //FERIEN
            //     klasse = "table-success"
            // }
            // if(day == "Sonntag")
            // {
            //     //SONNTAGE
            //     klasse = "table-warning";
            // }
            // if(date.official_Holiday == true)
            // {
            //     //FEIERTAGE
            //     klasse = "table-danger"
            // }
            // return klasse;


            //background: linear-gradient(to right, blue 0%, blue 50%, #fff 50%, #fff 100%);

            let day = moment(this.formatDate(date.date)).locale("de").format("dddd");

            let colors_new = [];
            for(let i = 0; i < this.holidays[index].length; i += 1)
            {
                colors_new.push(this.holidays[index][i].color);
            }
            
            if(date.official_Holiday == true)
            {
                //FEIERTAGE
                colors_new.push('#f5c6cb');
                return 'background-color: #f5c6cb';
            } else if(day == "Sonntag")
            {
                //SONNTAGE
                colors_new.push('#ffeeba');
                return 'background-color: #ffeeba';
            }

            if(colors_new.length == 0)
            {
                return "";
            }

            let string = "background: linear-gradient(to bottom,";
            for(let i = 0; i < colors_new.length; i += 1)
            {
                if(i == 0)
                {
                    string += colors_new[i] + " 0% " + (100/colors_new.length) + "%";
                } else
                {
                    string += ", " + colors_new[i] + " " +(100/colors_new.length)*(i) + "% " + (100/colors_new.length)*(i+1)+ "%";
                }
            }
            string += ");"
            return string;
        },
        generateHolidayTooltip(index) {
            let string = "";
            this.holidays[index].forEach(element => {
                string += element.type + " / ";
            });
            string += this.dates[index].official_holiday_name;
            string = string.slice(0, -1);
            string = string.slice(0, -1);
            string = string.slice(0, -1);

            return string;
        },

        //check employee has holidays on these day
        hasHoliday(employee, date)
        {
            let d = null;
            employee.private_holidays.forEach(element => {
                let g = moment(this.formatDate(date)).isBetween(moment(this.formatDate(element.start_date)), moment(this.formatDate(element.end_date)), 'days', []);
                if(g == true)
                {
                    d = element;
                }
            });


            return d;
        },

        //Formatting date
        generateDay(date)
        {
            return {'date': moment(date).format('YYYY-MM-DD'),'weekDay': moment(date).locale('de').format('dd') , 'day': moment(date).locale('de').format('DD')};
        },

        canEdit()
        {
            return parseInt(this.currentDate.format('YYYY')) <= this.selectedYear;
        },
        
        calculateUrlaubabspruch(employee)
        {
            let days = employee.holiday_days;
            days +=  employee.rest_holidays;
            
            
            let start_date;
            if(employee.start_date != null)
            {
                if(moment(this.formatDate(employee.start_date)).format('YYYY') == this.selectedYear)
                {
                    start_date = moment(employee.start_date);
                } else
                {
                    start_date = moment({
                        year: this.selectedYear,
                        month: 0,
                        day: 1
                    });
                }
            } else
            {
                start_date = moment({
                    year: this.selectedYear,
                    month: 0,
                    day: 1
                });
            }
            // let start_year = moment(this.selectedYear + "-01-01");
            let start_year = moment({
                                        year: this.selectedYear,
                                        month: 0,
                                        date: 1,
                                    });
            
            var currYear = parseInt(moment().format('YYYY'));
            let yearDays = employee.holiday_days / (moment({year: this.selectedYear, month: 11, date: 31}).diff(moment({year: this.selectedYear, month: 0, date: 1}), 'days', [])+1);


            // if(moment(employee.start_date).isBetween(moment(currYear + '-01-01'), moment(currYear + '-12-31')))
            if(moment(employee.start_date).isBetween(moment({year: currYear, month: 0, date: 1}), moment({year: currYear, month: 11, date: 31})))
            {
                days -= yearDays * start_year.diff(start_date, 'days') * -1;
            }

            if(employee.stop_date != null)
            {
                if(moment(employee.stop_date).isBetween(moment({year: currYear, month: 0, date: 1}), moment({year: currYear, month: 11, date: 31})))
                {
                    days -= yearDays * (moment(this.formatDate(employee.stop_date)).diff(moment({year: this.selectedYear, month: 11, date: 31}), 'days', []) * -1);
                }
            }

            if(employee.inactive_start != null && employee.inactive_stop != null)
            {
                days -= yearDays * (moment(this.formatDate(employee.inactive_start)).diff(moment(this.formatDate(employee.inactive_stop)), 'days', []) * -1);
            }

            return Math.round(days);
        },
        calculateRestHoliday(employee)
        {
            let days = this.calculateUrlaubabspruch(employee);

            // days = days / (moment(this.selectedYear + '-12-31').diff(moment(this.selectedYear + '-01-01'), 'days')+1) * start_year.diff(start_date, 'days') * -1;

            employee.private_holidays.forEach(element => {
                if(moment(this.formatDate(element.start_date)).isBetween(moment({year: this.selectedYear, month: 0, day: 1}), moment({year: this.selectedYear, month: 11, day: 31})) || moment(this.formatDate(element.end_date)).isBetween(moment({year: this.selectedYear, month: 0, day: 1}), moment({year: this.selectedYear, month: 11, day: 31})))
                {
                    if(element.state.name == 'confirmed' || element.state.name == 'requested' || element.state.name == 'returned_request')
                    {
                        let dates = this.getDates(element.start_date, element.end_date);
                        dates.forEach(date => {
                            let weekday = date.locale("de").format("dddd");
                            let official_holiday = date.locale("de").format("Y-MM-DD");
                            
                            if(date.isSameOrBefore(moment()))
                            {
                                if(weekday != 'Sonntag' && this.dates.find(item => item.date == official_holiday).official_Holiday == false)
                                {
                                    days -= 1;
                                }
                            }
                        });
                    }
                }
            });
            return Math.round(days);
        },
        calculateIllDays(employee)
        {
            let days = 0;
            employee.private_holidays.forEach(element => {
                if(moment(this.formatDate(element.start_date)).isBetween(moment({year: this.selectedYear, month: 0, day: 1}), moment({year: this.selectedYear, month: 11, day: 31})) || moment(this.formatDate(element.end_date)).isBetween(moment({year: this.selectedYear, month: 0, day: 1}), moment({year: this.selectedYear, month: 11, day: 31})))
                {
                    if(element.state.name == 'ill' || element.state.name == 'ill_confirmed')
                    {
                        let dates = this.getDates(element.start_date, element.end_date);
                        dates.forEach(date => {
                            let official_holiday = date.locale("de").format("Y-MM-DD");
                            if(this.dates.find(item => item.date == official_holiday).official_Holiday == false)
                            {
                                days += 1;
                            }
                        });
                    }
                }
            });
            return Math.round(days);
        },
        calculateUG(employee)
        {
            let days = 0;
            employee.private_holidays.forEach(element => {
                if(element.state.name == 'confirmed')
                {
                    if(moment(this.formatDate(element.start_date)).isBetween(moment({year: this.selectedYear, month: 0, day: 1}), moment({year: this.selectedYear, month: 11, day: 31})) || moment(this.formatDate(element.end_date)).isBetween(moment({year: this.selectedYear, month: 0, day: 1}), moment({year: this.selectedYear, month: 11, day: 31})))
                    {
                        let dates = this.getDates(element.start_date, element.end_date);
                        dates.forEach(date => {
                            let weekday = date.locale("de").format("dddd");
                            let official_holiday = date.locale("de").format("Y-MM-DD");
                            if(weekday != 'Sonntag' && this.dates.find(item => item.date == official_holiday).official_Holiday == false)
                            {
                                if(date.isSameOrBefore(moment()))
                                {
                                    days += 1;
                                }
                            }
                        });
                    }
                }
            });
            return Math.round(days);
        },
        calculateGU(employee)
        {
            let days = 0;
            employee.private_holidays.forEach(element => {
                if(element.state.name == 'confirmed')
                {
                    if(moment(this.formatDate(element.start_date)).isBetween(moment({year: this.selectedYear, month: 0, day: 1}), moment({year: this.selectedYear, month: 11, day: 31})) || moment(this.formatDate(element.end_date)).isBetween(moment({year: this.selectedYear, month: 0, day: 1}), moment({year: this.selectedYear, month: 11, day: 31})))
                    {
                        let dates = this.getDates(element.start_date, element.end_date);
                        dates.forEach(date => {
                            let weekday = date.locale("de").format("dddd");
                            let official_holiday = date.locale("de").format("Y-MM-DD");
                            if(weekday != 'Sonntag' && this.dates.find(item => item.date == official_holiday).official_Holiday == false)
                            {
                                if(date.isAfter(moment()))
                                {
                                    days += 1;
                                }
                            }
                        });
                    }
                }
            });
            return Math.round(days);
        },
        getDates(startDate, stopDate) {
            let dates = [];
            let currentDate = moment(this.formatDate(startDate));
            while(currentDate.isSameOrBefore(this.formatDate(stopDate)))
            {
                dates.push(moment(currentDate));
                currentDate = currentDate.add(1, 'days');
            }
            return dates;
        },
        openFormModal() {
            this.form.reset();
            this.form.employee_id = this.$auth.user().employee.id;
            this.form.start_date = null;
            this.form.end_date = null;
            this.form.status_id = null;
            this.openModal("createWish");
        },
        openFormModalStartEndDate(start, end) {
            this.form.reset();
            this.form.employee_id = this.$auth.user().employee.id;
            this.form.start_date = start;
            this.form.end_date = end;
            this.openModal("createWish");
        },

        createFormSubmit() {
            this.form
                .post("/private-holidays/store")
                .then((response) => {
                    this.closeModal("createWish")
                    let index = this.timeTracking.employees.findIndex(element => element.id == response.data.data.id);
                    this.timeTracking.employees.splice(index, 1, response.data.data);
                    this.form.reset();
                    this.$swal({
                        icon: "success",
                        title: "Dein Wunsch erstellt.",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {
                });
        },

        openWishModal(timeRange)
        {
            this.wishModal.fill(timeRange);
            this.openModal("wishModal");
        },

        editHolidayWishSubmit(evt)
        {
            evt.preventDefault();
            this.wishModal
                .post("/private-holidays/update")
                .then((response) => {
                    this.closeModal("wishModal");
                    let index = this.timeTracking.employees.findIndex(element => element.id == response.data.data.id);
                    this.timeTracking.employees.splice(index, 1, response.data.data);
                    this.$swal({
                        icon: "success",
                        title: "Dein Wunsch wurde gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {

                });
        },
        deleteWish()
        {
            this.wishModal
                .post("/private-holidays/destroy")
                .then((response) => {
                    this.closeModal("wishModal")
                    let index = this.timeTracking.employees.findIndex(element => element.id == response.data.data.id);
                    this.timeTracking.employees.splice(index, 1, response.data.data);
                    this.wishModal.reset();
                    this.$swal({
                        icon: "success",
                        title: "Dein Wunsch wurde gelöscht",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                })
        },


        openReturnedModal(timeRange)
        {
            this.returnedModal.fill(timeRange);
            this.returnedModal.compare_start_date = null;
            this.returnedModal.compare_end_date = null;
            this.newWish = false;
            this.openModal("returnedModal");
        },
        ReturnedSubmit() {
            this.returnedModal
                .post("/private-holidays/update")
                .then((response) => {
                    this.closeModal("returnedModal")
                    let index = this.timeTracking.employees.findIndex(element => element.id == response.data.data.id);
                    this.timeTracking.employees.splice(index, 1, response.data.data);
                    this.$swal({
                        icon: "success",
                        title: "Der Gegenvorschlag wurde gespeichert.",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {

                })
        },
        deleteReturnedRequest()
        {
            this.returnedModal
                .post("/private-holidays/destroy")
                .then((response) => {
                    this.closeModal("returnedModal")
                    let index = this.timeTracking.employees.findIndex(element => element.id == response.data.data.id);
                    this.timeTracking.employees.splice(index, 1, response.data.data);
                    this.wishModal.reset();
                    this.$swal({
                        icon: "success",
                        title: "Gegenvorschlag wurde gelöscht.",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                })
        },






        openModal(name)
        {
            this.$bvModal.show(name);
        },
        closeModal(name)
        {
            this.$bvModal.hide(name);
        },

        //Prüfen ob dort ein blockierter Tag ist
        isBlockedDay(date)
        {
            let d = false;
            this.timeTracking.blocked_days.forEach(element => {
                let g = moment(this.formatDate(date)).isBetween(moment(this.formatDate(element.start_date)), moment(this.formatDate(element.end_date)), 'days', []);
                if(g == true)
                {
                    
                    d = true;
                }
            });
            return d;
        },
        //Prüfen ob dort irgendwelche Ferien sind
        isHolidays(date)
        {
            let d = null;
            this.timeTracking.holidays.forEach(element => {
                let g = moment(this.formatDate(date)).isBetween(moment(this.formatDate(element.start_date)), moment(this.formatDate(element.end_date)), 'days', []);
                if(g == true)
                {

                    d = element;
                }
            });
            return d;
        },

        formatDate(date)
        {
            let element = date.split('-');
            return {
                year: parseInt(element[0]),
                month: parseInt(element[1])-1,
                date: parseInt(element[2]),
            }
        },

        calculateAllEmployeeHolidays() {
            this.dates.forEach((date, index) => {
                this.timeTracking.employees.forEach((employee, eIndex) => {
                    this.dates[index].holidays[eIndex] = this.hasHoliday(employee, date.date);
                })
            })
        },
        generateHolidays()
        {
            this.holidays = [];
            this.dates.forEach((element, index) => {
                this.holidays[index] = [];
                // this.holidays.push(this.isHolidays(element.date));
                this.timeTracking.holidays.forEach(holiday => {
                    let between = moment(this.formatDate(element.date)).isBetween(moment(this.formatDate(holiday.start_date)), moment(this.formatDate(holiday.end_date)), 'days', []);
                    if(between == true)
                    {
                        this.holidays[index].push(holiday);
                    }
                })
            });
        },
        isAbwesend(date, employee)
        {
            if(employee.inactive_start != null && employee.inactive_stop != null)
            {
                let date1 = moment(this.formatDate(employee.inactive_start));
                let date2 = moment(this.formatDate(employee.inactive_stop));
                let between = moment(this.formatDate(date)).isBetween(date1, date2, 'days', []);
                return between;
            }
            return false;
        },

        getDays()
        {
            this.axios
                .post("/private-holidays/show", {
                    year: this.selectedYear
                })
                .then((response) => {
                    this.timeTracking = response.data.data;
                    let currYear = moment().format('YYYY');
                    if(moment({year: this.selectedYear, month: 0, date: 1}).format('YYYY') == currYear)
                    {
                        this.$nextTick(()=>{
                            this.$refs.yes[0].scrollIntoView({ block: 'center',  behavior: 'smooth', inline: 'center' });
                        });
                    }
                    this.blockedDays = [];
                    this.holidays = [];
                    this.dates.forEach((element, index) => {
                        this.blockedDays.push(this.isBlockedDay(element.date));
                        this.dates[index].holidays = [];
                    });
                    this.generateHolidays();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },
        getStates()
        {
            this.axios
                .get('/states/holidays')
                .then((response) => {
                    this.states = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                })
        },
        getWorkplanWeeks()
        {
            this.axios
                .get("/private/workplan-weeks", {
                    params: {
                        filter_active: true
                    }
                })
                .then((response) => {
                    this.workplan = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden der Arbeitszeiten ist etwas schief gelaufen'
                    })
                })
        }
    },
    mounted() {
        this.selectedYear = parseInt(this.currentDate.format('YYYY'));


        this.generateMonths();
        this.generateYears();
        this.calculateDays();
        this.getDays();
        this.getStates();
        this.getGroups();
        this.getWorkplanWeeks();
    }
}
</script>

<style scoped>
.table th:first-child,
.table td:first-child {
  position: sticky;
  left: 0;
  z-index: 5;
}
.sticky {
  position: sticky;
  left: 150px;
  z-index: 5;
}


.first { position: sticky; top: 0; z-index: 99; background: white;}
.second { position: sticky; top: 3.8vh; z-index: 2; }


.date {
    width: 20px;
}
.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > thead > tr > td{
    border: 0.1px solid ;
    border-color: rgba(0, 0, 0, 0.1);
}
.hover:hover{
    background: #c7ffff !important;
}
table tr td{
    cursor: pointer;
}




.month:nth-child(odd) {
    background-color: #2e3d75 !important;
}
.month:nth-child(even) {
    background-color: #6579c9 !important;
}
.overflow {
    overflow: hidden !important; 
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    max-width: 2.5vw !important;
    min-width: 2.5vw !important;
    width: 2.5vw !important;
}
</style>